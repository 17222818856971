import type { VendorStatus } from '@server/service/book-now-service';
import { atom, type createStore } from 'jotai/vanilla';
import type { Bio, PhotoMedia } from 'types/vendor';

interface BookNowState {
	vendorName: string | null;
	vendorRelativeUri: string | null;
	vendorPhoto: PhotoMedia | null;
	selectedPackage: string | null;
	selectedDate: string | null;
	vendorId: string | null;
	writtenMessage: string | null;
	onboardingStatus: VendorStatus;
	vendorMarketCode: string | null;
	vendorCategoryCode: string | null;
	vendorBioName: Bio | null;
}

export const initialState: BookNowState = {
	vendorName: null,
	vendorRelativeUri: null,
	vendorPhoto: null,
	selectedPackage: null,
	selectedDate: null,
	vendorId: null,
	writtenMessage: null,
	onboardingStatus: 'NOT_STARTED',
	vendorMarketCode: null,
	vendorCategoryCode: null,
	vendorBioName: null,
};

export const vendorNameAtom = atom(initialState.vendorName);
export const vendorRelativeUriAtom = atom(initialState.vendorRelativeUri);
export const vendorPhotoAtom = atom(initialState.vendorPhoto);
export const selectedPackageAtom = atom(initialState.selectedPackage);
export const selectedDateAtom = atom(initialState.selectedDate);
export const vendorIdAtom = atom(initialState.vendorId);
export const writtenMessageAtom = atom(initialState.writtenMessage);
export const onboardingStatusAtom = atom(initialState.onboardingStatus);
export const vendorMarketCodeAtom = atom(initialState.vendorMarketCode);
export const vendorCategoryCodeAtom = atom(initialState.vendorCategoryCode);
export const vendorBioNameAtom = atom(initialState.vendorName);

export const getBookNowDataToHydrate = (
	store: ReturnType<typeof createStore>,
) => {
	return {
		vendorName: store.get(vendorNameAtom),
		vendorRelativeUri: store.get(vendorRelativeUriAtom),
		vendorPhoto: store.get(vendorPhotoAtom),
		selectedPackage: store.get(selectedPackageAtom),
		selectedDate: store.get(selectedDateAtom),
		vendorId: store.get(vendorIdAtom),
		onboardingStatus: store.get(onboardingStatusAtom),
		vendorMarketCode: store.get(vendorMarketCodeAtom),
		vendorCategoryCode: store.get(vendorCategoryCodeAtom),
		vendorBio: store.get(vendorBioNameAtom),
	};
};

export const getBookNowInitialValues = (
	values: ReturnType<typeof getBookNowDataToHydrate>,
) => {
	const {
		vendorName,
		vendorRelativeUri,
		vendorPhoto,
		selectedPackage,
		selectedDate,
		vendorId,
		onboardingStatus,
		vendorMarketCode,
		vendorCategoryCode,
		vendorBio,
	} = values || {};
	return [
		[vendorNameAtom, vendorName || initialState.vendorName],
		[
			vendorRelativeUriAtom,
			vendorRelativeUri || initialState.vendorRelativeUri,
		],
		[vendorPhotoAtom, vendorPhoto || initialState.vendorPhoto],
		[selectedPackageAtom, selectedPackage || initialState.selectedPackage],
		[selectedDateAtom, selectedDate || initialState.selectedDate],
		[vendorIdAtom, vendorId || initialState.vendorId],
		[onboardingStatusAtom, onboardingStatus || initialState.onboardingStatus],
		[vendorMarketCodeAtom, vendorMarketCode || initialState.vendorMarketCode],
		[
			vendorCategoryCodeAtom,
			vendorCategoryCode || initialState.vendorCategoryCode,
		],
		[vendorBioNameAtom, vendorBio || initialState.vendorBio],
	];
};
