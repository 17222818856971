import {
	fieldValueAtom,
	semanticSearchInitialState,
	semanticSearchResponseAtom,
} from '@components/listings-search-bar/atoms';
import type { createStore } from 'jotai/vanilla';

export const getSemanticSearchDataToHydrate = (
	store: ReturnType<typeof createStore>,
) => {
	return {
		fieldValue: store.get(fieldValueAtom),
		semanticSearchResponse: store.get(semanticSearchResponseAtom),
	};
};

export const getSemanticSearchInitialValues = (
	values: ReturnType<typeof getSemanticSearchDataToHydrate>,
) => {
	const { fieldValue, semanticSearchResponse } = values || {};

	return [
		[fieldValueAtom, fieldValue || semanticSearchInitialState.searchFieldValue],
		[
			semanticSearchResponseAtom,
			semanticSearchResponse || semanticSearchInitialState,
		],
	];
};
